@import "node_modules/@heer.dev/una-theme-kit/dist/scss/base"; // una.css

// Theme Variables
:root {
  --min-width: 1440px;
  --family-1: 'Arial', sans-serif;
  --family-2: 'Dosis', sans-serif;
  --font-size-1: .8rem;
  --font-size-2: .95rem;
  --font-size-3: 1.3rem;
  --font-size-4: 2rem;
  --line-height-1: 1;
  --line-height-2: 1.5;
  --line-height-3: 1.7;
  --height-1: 50px;
  --height-2: 70px;
  --height-3: 100px;
  --space-1: .5rem;
  --space-2: 1rem;
  --space-3: 5rem;
  --radius-1: 30px;
  --radius-2: 150px;
}

// Theme Colors
.light {
  --color-1: hsl(27, 59%, 21%);
  --color-2: hsl(11, 45%, 51%);
  --on-color: hsl(45, 41%, 100%);
  --background-1: hsl(0, 0%, 98%);
  --background-2: hsl(0, 0%, 100%);
  --background-3: hsl(39, 43%, 97%);
  --on-background: hsl(0, 0%, 50%);
  --border: hsl(0, 0%, 90%);
  --error: #c42600;
  --warning: #d38f00;
  --success: #009600;
}
.dark {
  --color-1: hsl(0, 0%, 95%);
  --on-color: hsl(0, 0%, 90%);
  --background-1: hsl(225, 6%, 10%);
  --background-2: hsl(225, 6%, 17%);
  --background-3: hsl(225, 6%, 18%);
  --on-background: hsl(0, 0%, 95%);
  --border: hsl(225, 6%, 17%);
  --error: #c42600;
  --warning: #d38f00;
  --success: #009600;
}

// Media Queries
@media screen and (min-width: 768px) {} // Tablets
@media (min-width: 1180px) {} // Laptops
@media screen and (min-width: 2561px) {} // 4k

// Mini Reset
body {
  font-weight: 400;
  line-height: var(--line-height-2);
  ul {
    padding: 0 0 0 1rem;
    margin: 0;
    li {
      line-height: var(--line-height-2);
    }
  }
  img {
    width: 100%;
  }
}

.gateway {
  display: grid;
  grid-auto-flow: row;
  gap: 1px;
  .case {
    padding: var(--space-3) var(--space-2);
    background: var(--color-1);
    h3 {
      font-size: var(--font-size-4);
      line-height: 1;
      color: var(--on-color);
    }
    .spacer {
      display: inline-block;
      width: 25%;
      height: 5px;
      background: var(--background-2);
      margin: var(--space-2) 0;
    }
    p {
      color: var(--on-color);
      font-size: var(--font-size-3);
      a {
        color: var(--on-color);
      }
    }
  }
}
@media screen and (min-width: 1180px) { // Laptops
  .gateway {
    //grid-template-rows: 1fr;
    //grid-template-columns: 1fr 1fr;
    gap: 1px;
    grid-auto-flow: column;
    .case {
      padding: var(--space-3);
    }
  }
}

a.button {
  display: inline-flex;
  align-items: center;
  margin-top: 2rem;
  background: var(--background-1);
  color: var(--on-background);
  padding: 1rem;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid var(--border);
  background: var(--background-1);
  span {
    margin-right: .5rem;
  }
}
